import { ApolloProvider } from '@apollo/client';
import { CmsProvider, UiduProvider } from '@uidu/api.js/react';
import GlobalStyles from 'components/GlobalStyles';
import { useApollo } from 'lib/apollo';
import { SessionProvider } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import NextHead from 'next/head';

const defaultSeo = {
  title: undefined,
  titleTemplate: '%s | Welfare Beatrice',
  defaultTitle: 'Welfare Beatrice',
  description:
    'Welfare Beatrice è un progetto che unisce persone, aziende e comuni della Val Seriana che vogliono lavorare insieme.',
  twitter: {
    cardType: 'summary_large_image',
  },
  openGraph: {
    type: 'website',
    images: [{ url: '/Beatrice-cover-1200x620.jpg' }],
  },
};

const App = ({ Component, pageProps: { session, ...pageProps } }) => {
  const client = useApollo(pageProps.initialApolloState, { session });
  return (
    <SessionProvider session={session}>
      <UiduProvider endpoint={process.env.NEXT_PUBLIC_API_ENDPOINT}>
        <CmsProvider projectId={process.env.NEXT_PUBLIC_PROJECT_ID}>
          <ApolloProvider client={client}>
            <DefaultSeo {...defaultSeo} />
            <NextHead>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
              <link rel="manifest" href="/favicons/manifest.json" />
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta
                name="msapplication-TileImage"
                content="/ms-icon-144x144.png"
              />
              <meta name="theme-color" content="#ffffff" />
            </NextHead>
            <div>
              <GlobalStyles />
              <Component {...pageProps} />
            </div>
          </ApolloProvider>
        </CmsProvider>
      </UiduProvider>
    </SessionProvider>
  );
};

export default App;
