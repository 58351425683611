import React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    ${tw`font-size[.95rem] lg:font-size[.9rem]`}
  }
  
  body {
    ${tw`overflow-x-hidden text-base antialiased text-gray-800 overscroll-y-none`}
    letter-spacing: -.01em;
    font-family: 'Montserrat', sans-serif;
  }

  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px !important;
  }

  :root {
    --brand-primary: 224, 158, 60;
    --brand-secondary: 11, 120, 162;
    --brand-subtle: 246, 246, 246;

    --brand-danger: 239, 68, 68;
    --brand-warning: 251, 191, 36;
    --brand-success: 251, 191, 36;

    --swiper-theme-color: #e09e3c;
    --swiper-pagination-bullet-width: 12px;
    --swiper-pagination-bullet-height: 12px;

    /* --brand-on-danger: 239, 68, 68; */
    /* --brand-on-warning: 251, 191, 36; */
    /* --brand-on-success: 251, 191, 36; */

    --brand-on-primary: 255, 255, 255;
    --brand-on-secondary: 10, 10, 10;
    --brand-on-subtle: 10, 10, 10;

    --body-primary-bg: 255, 255, 255;
    --body-secondary-bg: 249, 250, 251;
    --body-primary-color: 113, 113, 113;
    --body-secondary-color: black;
    --body-on-primary-bg: 255, 254, 253;
    --body-on-primary-hover-bg: 245, 244, 243;
    --body-on-secondary-bg: 220, 220, 220;

    --border: 211, 211, 211;
    --border-strong: black;
    --tw-ring-color: rgba(var(--brand-primary), 0.5);
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
